import { goto } from '$app/navigation';

export interface LicenseDetails {
  quantity: number;
  type: 'group' | 'testing';
  starts: Date;
  months: 6 | 12;
  expectedSubtotal: number;
}

export function onLicenseQuoteRequested(evt: CustomEvent<LicenseDetails>) {
  redirectForLicenseQuote(evt.detail);
}

export function redirectForLicenseQuote(licenseDetails: LicenseDetails) {
  const params = new URLSearchParams();
  params.set('licenseType', licenseDetails.type);
  params.set('quantity', `${licenseDetails.quantity}`);
  params.set('starts', licenseDetails.starts.toISOString());
  params.set('months', `${licenseDetails.months}`);
  goto(`/teach/quote?${params.toString()}`);
}

export function onLicensePurchaseRequested(evt: CustomEvent<LicenseDetails>) {
  redirectForLicensePurchase(evt.detail);
}

export function redirectForLicensePurchase(licenseDetails: LicenseDetails) {
  const params = new URLSearchParams();
  params.set('licenseType', licenseDetails.type);
  params.set('quantity', `${licenseDetails.quantity}`);
  params.set('starts', licenseDetails.starts.toISOString());
  params.set('months', `${licenseDetails.months}`);
  params.set('completePurchase', 'true');

  goto(`/app/teach/licenses/purchase?${params.toString()}`);
}
